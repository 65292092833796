

import {computed, defineComponent, h, ref, watch} from "vue";
import {
  IonCard,
  IonCardContent,
  IonCol,
  IonContent,
  IonGrid,
  IonPage,
  IonRow,
  IonText,
  IonItem,
  IonLabel,
  IonList,
  IonSearchbar,
  IonButton,
  modalController,
  isPlatform,
  IonItemDivider,
  IonCardHeader, IonCardTitle, IonCardSubtitle
} from "@ionic/vue";

import {useStore} from "vuex";
import _, {map} from "lodash";
import UserListItemComponent from "@/app/view/user/list/UserListItemComponent.vue";
import {User} from "@/domain/model/user/user.model";
import UserCreatePage from "@/app/view/user/create/UserCreatePage.vue";
import {useUserApi} from "@/domain/service/api/use-user-api";
import UserListXlsImporterComponent from "@/app/view/user/list/UserListXlsImporterComponent.vue";
import {useConfig} from "@/domain/service/kernel/config";
import {NButton, NDataTable, NSpace, NTag} from "naive-ui";
import {Material} from "@/domain/model/material/material.model";
import UserDetailPage from "@/app/view/user/detail/UserDetailPage.vue";
import {useRouter} from "vue-router";

export default defineComponent({
  name: "UserListPage",
  components: {
    UserListItemComponent,
    UserListXlsImporterComponent,
    IonPage,
    IonCardHeader,
    IonCardTitle,
    IonCardSubtitle,
    IonText,
    IonContent,
    IonGrid,
    IonRow,
    IonCol,
    IonCard,
    IonItemDivider,
    IonCardContent,
    IonList,
    IonSearchbar,
    IonButton,
    NSpace,
    NDataTable,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const searchWord = ref('');
    // const maxResults = ref(25);

    const data = ref<any>([]);
    const isTablet = computed<boolean>(() => !isPlatform('desktop'));
    const {fetchAll} = useUserApi();
    const searchUser = (user: User, word: string): boolean => {
      if(user.email)
      return user.username.toLowerCase().includes(word.toLowerCase()) ||
      user.email.toLowerCase().includes(word.toLowerCase())
      else
        return user.username.toLowerCase().includes(word.toLowerCase())
    };
    const filterUser = (users: User[], word: string): User[] => _.filter(users, (user: User) => searchUser(user, word));
    const sortUser = (users: User[]): User[] => _.sortBy(users, ['name', 'asc']);
    const users = computed(() => sortUser(filterUser(store.getters.allUsers.filter((user: User) => user.appAccess !== 'allowed'), searchWord.value)));
    const isEmpty = computed(() => {
      return store.getters.allUser.length === 0;
    });

    const onAddUser = async () => {
      const modal = await modalController
          .create({
            component: UserCreatePage,
            componentProps: {employees: true}
          });
      return modal.present();
    };
    const onEditUser = async (id: string) => {
      const modal = await modalController
          .create({
            component: UserDetailPage,
            componentProps: {id},
          });
      return modal.present();
    };
    const onGoUpdateUserDetails = async (id: string) => {
      await router.push({
        name: 'UserProfileUpdate',
        params: {id: id}
      });
    }

    const charge = (userCharge: string) => {
      if (userCharge === 'employee_stock')
        return 'Operario';
      else if (userCharge === 'ceo')
        return 'Directivo';
      else if (userCharge === 'employee_production')
        return 'Empleado';
      else if (userCharge === 'manager_production')
        return 'Jefe de planta';
      else
        return 'Recursos Humanos';

    };
    const shift = (userShift: string) => {
      if (userShift === 'morning')
        return 'Mañana';
      else if (userShift === 'afternoon')
        return 'Tarde';
      else if (userShift === 'night')
        return 'Noche';
      else if (userShift === 'central')
        return 'Central';
      else if (userShift === 'sick_leave')
        return 'Baja Médica';
      else
        return 'Sin especificar';
    };

    const replaceData = () => {
      data.value =
          map(users.value, (user: User) => {
            return {
              id: user.id,
              name: !user.name ? '' : user.name,
              surname: !user.surname ? '' : user.surname,
              number: user.workerNumber,
              charge: charge(user.charge),
              shift: shift(user.shift),
            };
          });
    };

    watch(users, () => replaceData(), {immediate: true});

    fetchAll();

    return {
      data,
      columns: [
        {
          title: "Nombre",
          key: "name",
          sorter: 'default'
        },
        {
          title: "Apellido",
          key: "surname",
          sorter: 'default'
        },
        {
          title: "Número de operario",
          key: "number",
          sorter: 'default'
        },
        {
          title: "Cargo",
          key: "charge",
          sorter: 'default'
        },
        {
          title: "Turno",
          key: "shift",
          sorter: 'default'
        },
        {
          title: 'Acciones',
          key: 'actions',
          render(row: any): any {
            const buttonsNames = ['Editar empleado', 'Detalles perfil'];
            let buttons = null;
            buttons = buttonsNames.map((button) => {
              return h(
                  NButton,
                  {
                    size: 'small',
                    onClick: () => button === 'Editar empleado' ? onEditUser(row.id) : onGoUpdateUserDetails(row.id),
                  },
                  {default: () => button},
              );
            })
            return buttons;
          },
        },
      ],
      isTablet,
      searchWord,
      users,
      isEmpty,
      onAddUser,
      isProd: useConfig().isProduction(),
    };
  }
})
