import {Excel} from "@/domain/model/excel/excel.model";
import {uuid4} from "@capacitor/core/dist/esm/util";
import {useStore} from "vuex";
import {CreateUser, UpdateUser, UpdateUserWithProfileData} from "@/domain/command/user/user.command";
import {useUserApi} from "@/domain/service/api/use-user-api";
import {loadingController} from "@ionic/vue";
import _, {lowerCase} from "lodash";
import {Shift, User} from '@/domain/model/user/user.model';

export function useUserImporter() {
    const store = useStore();
    const importFromExcel = async (excel: Excel) => {
        await useUserApi().fetchAll();
        const loading = await loadingController
            .create({
                message: 'Cargando excel, esta acción puede tardar algunos minutos...',
                duration: 100000,
            });

        await loading.present();
        const allCreateUsers = [];
        const allUpdateProfileDataUsers = [];
        const allUpdateUsers = [];
        const sheet = excel.sheets[0];
        const firstRowWithName = {x: 0, y: 1};
        const firstRowWithSurname = {x: 1, y: 1};
        const firstRowWithCharge = {x: 2, y: 1};
        const firstRowWithShift = {x: 3, y: 1};
        const firstRowWithPassword = {x: 4, y: 1};
        const firstRowWithEmail = {x: 5, y: 1};
        const firstRowWithAddress = {x: 6, y: 1};
        const firstRowWithPhone = {x: 7, y: 1};
        const firstRowWithDni = {x: 8, y: 1};


        for (let index = 0; index < sheet.cellRows.length; index++) {
            const row = sheet.cellRows[index];

            if (index < firstRowWithName.y)
                continue;
            // if (!row[firstRowWithKit.x])
            //     continue;
            const excelEmployeeProduction = 'Operario';
            const excelManagerProduction = 'Encargado';
            const excelManagerProduction2 = 'Encargada';
            const id = uuid4();
            const name = !row[firstRowWithName.x]?.value ? '' : row[firstRowWithName.x].value.toString();
            const surname = !row[firstRowWithSurname.x]?.value ? '' : row[firstRowWithSurname.x].value.toString();
            const password = !row[firstRowWithPassword.x]?.value ? '' : row[firstRowWithPassword.x].value.toString();
            let email = !row[firstRowWithEmail.x]?.value ? '' : row[firstRowWithEmail.x].value.toString();
            const address = !row[firstRowWithAddress.x]?.value ? '' : row[firstRowWithAddress.x].value.toString();
            const phone = !row[firstRowWithPhone.x]?.value ? '' : row[firstRowWithPhone.x].value.toString();
            const workNumber = !row[firstRowWithDni.x].value ? '' : row[firstRowWithDni.x].value.toString();
            let charge = !row[firstRowWithCharge.x].value ? '' : row[firstRowWithCharge.x].value.toString();
            let shift = !row[firstRowWithShift.x]?.value ? '' : row[firstRowWithShift.x].value.toString();
            if (charge === excelEmployeeProduction)
                charge = 'employee_production';
            if (charge === excelManagerProduction || charge === excelManagerProduction2)
                charge = 'manager_production';
            if (store.getters.userByEmail(email) || _.find(allCreateUsers, (createUser: CreateUser) => createUser.email === email))
                email = '';

            if (shift === '' || lowerCase(shift) == 'central')
                shift = 'central'
            if (shift.toLowerCase() === 'mañana' || shift.toLowerCase() === 'mañanas')
                shift = 'morning'
            if (shift.toLowerCase() === 'tarde' || shift.toLowerCase() === 'tardes')
                shift = 'afternoon'
            if (shift.toLowerCase() === 'noche' || shift.toLowerCase() === 'noches')
                shift = 'night'
            if (shift.toLowerCase() === 'baja medica' || shift.toLowerCase() === 'baja médica')
                shift = 'sick_leave'

            let username = name + surname;
            username = username.split(' ').join('');

            let userNameIndex = 0;

            while (store.getters.userByUsername(username)) {
                userNameIndex++;
                username = name + surname + userNameIndex;
                username = username.split(' ').join('');
            }

            const user: User | undefined = store.getters.userByWorkNumber(Number(workNumber));
            if (!user) {
                console.log('creating...');
                const createUser: CreateUser = {
                    id: id,
                    username: username,
                    email: email,
                    password: !password ? '' : password,
                    charge: charge,
                    shift: shift,
                    workerNumber: Number(workNumber),
                }

                allCreateUsers.push(createUser);
                const updateUserWithProfileData: UpdateUserWithProfileData = {
                    id: id,
                    name: name,
                    surname: surname,
                    address: address,
                    phone: phone,
                    dni: '',
                }

                allUpdateProfileDataUsers.push(updateUserWithProfileData);
            } else {
                console.log('updating ' + user.username + '...');
                const updateUserWithProfileData: UpdateUserWithProfileData = {
                    id: user.id,
                    name: name,
                    surname: surname,
                    address: address,
                    phone: phone,
                    dni: '',
                }
                allUpdateProfileDataUsers.push(updateUserWithProfileData);
                const updateUser: UpdateUser = {
                    id: user.id,
                    username: username,
                    email: email,
                    charge: charge,
                    shift: shift,
                    dailyBonusIncome: user.dailyBonusIncome,
                }
                allUpdateUsers.push(updateUser);
            }


        }
        await useUserApi().createAndUpdateProfileAllUsers(allCreateUsers, allUpdateProfileDataUsers, allUpdateUsers);
        await loading.dismiss();
    }

    return {importFromExcel};
}